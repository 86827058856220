<template>
  <b-card
  >
    <div class="m-2">
      <b-overlay
          :show="show"
          no-wrap
      />
      <b-row>
        <b-col
            style="margin-top: 20px"
            cols="12"
        >
          <a href="/crear_inventario">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"

                variant="primary"

            >
              Entradas
            </b-button>
          </a>
        </b-col>
        <b-col
            style="margin-top: 20px"
            cols="12" lg="6"
        >
          <b-form-group
              label="Referencia"

          >
            <b-input-group size="md">
              <b-form-input
                  id="basicInput"
                  type="search"
                  @search="resetFilterReference"
                  @keyup="searchForReference"
                  v-model="reference"
                  placeholder="Buscar"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
            style="margin-top: 20px"
            cols="12" lg="6"
        >
          <b-form-group
              label="Proveedor"

          >
            <v-select
                style=" border-radius: 1px !important;"
                v-model="filter_partner_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="value"
                @input="searchByPartner()"
                :options="optionsPartners"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="6" class="mt-2">
          <div>
            <b-form-group

                label="Estatus"
            >
              <v-select
                  style="width: 100%; border-radius: 1px !important;"
                  v-model="typeStatus"
                  @input="initialize"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="value"
                  :options="optionsStatus"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="mt-2">
          <b-form-group
              label="Fecha"
          >
            <flat-pickr
                v-model="send_date"
                @on-change="sendChangeDate()"
                class="form-control"
                :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
            />
            <div v-if="send_date"
                 style="position: absolute; margin-top: -28px; right: 30px; z-index: 100; cursor: pointer"
                 @click="clearDate">
              <strong>X</strong>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
              striped
              hover
              responsive
              :current-page="currentPage"
              :items="items"
              :fields="fields"
          >
            <template #cell(status)="data">
              {{ changeTextSpanishSingleInventory(data.item.status) }}
            </template>
            <template #cell(actions)="data">

              <span v-if="data.item.status === 'done'" v-b-tooltip.hover title="Visualizar">
                 <router-link :to="`/detalle_inventario/${data.value.id}`" style="color: darkblue">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="33" height="33"
                     viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                     stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"/>
  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"/>
</svg>
                     </router-link>
              </span>

              <span v-else v-b-tooltip.hover title="Editar Inventario">
                    <router-link :to="`/editar_inventario/${data.value.id}`" style="color: darkcyan">
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                           class="icon icon-tabler icon-tabler-file-pencil" width="30"
                                           height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                           fill="none"
                                           stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"/>
                      <path d="M10 18l5 -5a1.414 1.414 0 0 0 -2 -2l-5 5v2h2z"/>
                    </svg>
                   </router-link>
              </span>
            </template>
          </b-table>
        </b-col>

        <b-col
            cols="12"
        >
          <b-pagination
              @change="changePage"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
          />
        </b-col>
      </b-row>

    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  VBTooltip,
  BOverlay,
  BModal,
  VBModal, BFormCheckbox, BFormCheckboxGroup

} from 'bootstrap-vue'
import vSelect from "vue-select";
import {mapActions, mapGetters} from "vuex";
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {VueGoodTable} from 'vue-good-table'
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "ListInt ",
  components: {
    VueGoodTable,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip, BModal,
    VBModal,
    ModalAppointment, flatPickr, BFormCheckbox, BFormCheckboxGroup, vSelect
  },
  data() {
    return {
      partner_id: null,
      send_date: null,
      filter_partner_id: null,
      optionsPartners: [],
      lots: null,
      typeInventory: 'FM/IN/',
      reference: null,
      perPage: 10,
      show: false,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      searchTimeout: null,
      typeStatus: {id: "", value: "Seleccione un estatus"},
      optionsStatus: [
        {id: "draft", value: "Borrados"},
        {id: "assigned", value: "Listo"},
        {id: "done", value: "Hecho"},
        {id: "", value: "Seleccione un estatus"},
      ],
      currentPage: 1,
      fields: [
        {
          key: 'id', label: 'id', sortable: true
        },
        {
          key: 'name', label: 'Referencia'
        },
        {
          key: 'partner', label: 'Proveedor'
        },
        {
          key: 'day', label: 'Dia'
        },
        {
          key: 'status', label: 'Estatus'
        },
        {
          key: 'actions', label: 'Acciones',
        },
      ],
      items: [],
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-modal': VBModal,
  },
  async created() {

    await this.initialize();
    await this.findPartners();
  },
  methods: {
    ...mapActions('inventory', ['findAllInventory', 'findListPartners']),
    async changePage(page) {
      this.currentPage = page
      await this.initialize()

    },
    async clearDate() {
      this.currentPage = null;
      this.totalRows = null;
      this.send_date = null;
      await this.initialize();
    },
    async sendChangeDate() {
      await this.initialize()
    },
    async searchByPartner() {
      this.partner_id = this.filter_partner_id ? this.filter_partner_id.id : null;
      this.currentPage = 1;
      await this.initialize();


    },
    async searchForReference() {
      if (this.reference) {
        if (this.reference.length >= 3) {
          if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
          }
          this.searchTimeout = setTimeout(async () => {
            this.currentPage = 1;
            this.totalRows = null;
            await this.initialize()
          }, 300);

        }
      } else {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(async () => {
          this.reference = null
          this.currentPage = 1;
          this.totalRows = null;
          await this.initialize()
        }, 300);

      }

    },
    async resetFilterReference() {
      if (!this.reference) {
        this.currentPage = 1;
        this.totalRows = null;
        this.reference = null;
        await this.initialize();
      }
    },
    async initialize() {
      this.show = true
      const queryParams = {};

      if (this.currentPage > 1) {
        queryParams.page = this.currentPage
      } else {
        this.totalRows = null;
        this.currentPage = 1
        queryParams.page = 1
      }
      if (this.typeStatus?.id && this.typeStatus) {
        queryParams.state = this.typeStatus.id
      }
      if (!this.typeStatus) {
        this.typeStatus = {id: "", value: "Seleccione un estatus"}
        queryParams.state = ''
      }

      if (this.reference) {
        queryParams.reference = this.reference
      }
      if (this.partner_id) {
        queryParams.partner_id = this.partner_id
      }
      if (this.send_date) {

        queryParams.send_date = this.send_date
      }
      queryParams.typeInventory = this.typeInventory
      this.items = [];
      const queryString = Object.keys(queryParams)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
          .join('&');

      const response = await this.findAllInventory(queryString);
      if (response.data.length <= 0) {
        this.items = [];
        this.show = false
        return
      }
      this.totalRows = response.pagination.totalItems;
      for (const a of response.data) {
        this.items.push({
          id: a.id,
          name: a.name,
          status: a.state,
          day: this.resHoursOdoo(a.scheduled_date),
          origin: a.origin,
          partner: a.ResPartnerAppointment ? a.ResPartnerAppointment.name : 'No tiene proveedor',
          actions: {
            id: a.id,
          }
        })
      }
      this.show = false;
      return
    },
    async findPartners() {
      const response = await this.findListPartners();
      for (const a of response.partners) {
        this.optionsPartners.push({
          id: a.id,
          value: a.name
        })

      }
    },

  },

  computed: {
    sortOptions() {
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({text: f.label, value: f.key}))

      // Create an options list from our fields
    },
  },

}
</script>

<style scoped>

</style>